import * as React from 'react'
const SvgStarMapsLogo = (props) => (
  <svg viewBox='0 0 36 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fill='#fff' d='M0 0h36v40H0z' />
    <path fill='#27292C' d='M-131-26h1411v92H-131z' />
    <mask id='StarMapsLogo_svg__a' maskUnits='userSpaceOnUse' x={0.5} y={-0.5} width={35} height={41} fill='#000'>
      <path fill='#fff' d='M.5-.5h35v41H.5z' />
      <path d='M18 1.5 1.5 10.75v18.5L18 38.5l16.5-9.25v-18.5L18 1.5Zm-1.143 34.465a2.294 2.294 0 0 0-.551.314L4.35 29.577c.134-1.215-.551-2.365-1.695-2.849v-13.43c.202-.079.377-.183.552-.314l11.955 6.702c-.135 1.215.55 2.365 1.694 2.849v13.43Zm-.565-18.2L4.337 11.065c.027-.21.027-.418 0-.628l11.955-6.702a2.933 2.933 0 0 0 3.389 0l11.955 6.702c-.027.21-.027.418 0 .628L19.68 17.766a2.933 2.933 0 0 0-3.389 0Zm17.051 8.872c-1.143.483-1.842 1.646-1.694 2.848l-11.955 6.703a4.574 4.574 0 0 0-.551-.314l-.013-13.34c1.143-.483 1.842-1.646 1.694-2.848l11.968-6.78c.175.117.363.222.551.313v13.418Z' />
    </mask>
    <path
      d='M18 1.5 1.5 10.75v18.5L18 38.5l16.5-9.25v-18.5L18 1.5Zm-1.143 34.465a2.294 2.294 0 0 0-.551.314L4.35 29.577c.134-1.215-.551-2.365-1.695-2.849v-13.43c.202-.079.377-.183.552-.314l11.955 6.702c-.135 1.215.55 2.365 1.694 2.849v13.43Zm-.565-18.2L4.337 11.065c.027-.21.027-.418 0-.628l11.955-6.702a2.933 2.933 0 0 0 3.389 0l11.955 6.702c-.027.21-.027.418 0 .628L19.68 17.766a2.933 2.933 0 0 0-3.389 0Zm17.051 8.872c-1.143.483-1.842 1.646-1.694 2.848l-11.955 6.703a4.574 4.574 0 0 0-.551-.314l-.013-13.34c1.143-.483 1.842-1.646 1.694-2.848l11.968-6.78c.175.117.363.222.551.313v13.418Z'
      stroke='#fff'
      strokeWidth={2}
      strokeDasharray='1 2'
      mask='url(#StarMapsLogo_svg__a)'
    />
    <rect x={-150.5} y={-45.5} width={1450} height={386} rx={4.5} stroke='#9747FF' strokeDasharray='10 5' />
  </svg>
)
export default SvgStarMapsLogo
